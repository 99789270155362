import React from "react";
import Layout from "../components/layout";
import Image from "../components/image";

const SimilyPage = () => {
    return (
        <Layout pageTitle="Simily">
            <h1>Simily</h1>
            <Image/>
        </Layout>
    )
}

export default SimilyPage